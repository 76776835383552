<template>
  <div>
    <v-simple-table class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center">Thông số</th>
            <th class="text-center">Nội tỉnh</th>
            <th class="text-center">Liên tỉnh</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">Vận chuyển</td>
            <td class="text-center">
              <v-autocomplete
                class="c-input-xs"
                v-model="customerContract.id_delivery_contract_provincial"
                :items="deliveryOptions"
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </td>
            <td class="text-center">
              <v-autocomplete
                class="c-input-xs"
                v-model="customerContract.id_delivery_contract_inter_provincial"
                :items="deliveryOptions"
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </td>
          </tr>

          <template
            v-if="
              !isSscIds.includes(
                customerContract.id_delivery_contract_provincial
              ) ||
              !isSscIds.includes(
                customerContract.id_delivery_contract_inter_provincial
              )
            "
          >
            <tr>
              <td class="text-center">Auto đổi vận chuyển</td>
              <td class="text-center vertical-align-middle">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="
                      customerContract.auto_change_delivery_company_provincial
                    "
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_provincial
                      ) || deliveryContracts.length < 2
                    "
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
              <td class="text-center vertical-align-middle">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="
                      customerContract.auto_change_delivery_company_inter_provincial
                    "
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_inter_provincial
                      ) || deliveryContracts.length < 2
                    "
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                SSC vận chuyển <br />
                (khi cần)
              </td>
              <td class="text-center vertical-align-middle">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="customerContract.ssc_delivery_company_provincial"
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_provincial
                      )
                    "
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
              <td class="text-center vertical-align-middle">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="
                      customerContract.ssc_delivery_company_inter_provincial
                    "
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_inter_provincial
                      )
                    "
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
            </tr>
            <template
              v-if="
                !isSscIds.includes(
                  customerContract.id_delivery_contract_provincial
                ) ||
                !isSscIds.includes(
                  customerContract.id_delivery_contract_inter_provincial
                )
              "
            >
              <tr>
                <td class="text-center">
                  Khai giảm khối lượng <br />
                  (300đ /đơn)
                </td>
                <td class="text-center">
                  <v-text-field
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_provincial
                      )
                    "
                    v-model.number="customerContract.decrease_weight_provincial"
                    class="c-input-small c-input-mini-append-icon"
                    :placeholder="$t('labels.gram')"
                    type="number"
                    append-icon="g"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </td>
                <td class="text-center">
                  <v-text-field
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_inter_provincial
                      )
                    "
                    v-model.number="
                      customerContract.decrease_weight_inter_provincial
                    "
                    class="c-input-small c-input-mini-append-icon"
                    :placeholder="$t('labels.gram')"
                    type="number"
                    append-icon="g"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  Khai cố định khối lượng <br />
                  (500đ /đơn)
                </td>
                <td class="text-center">
                  <v-text-field
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_provincial
                      )
                    "
                    v-model.number="customerContract.fixed_weight_provincial"
                    class="c-input-small c-input-mini-append-icon"
                    :placeholder="$t('labels.gram')"
                    type="number"
                    append-icon="g"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </td>
                <td class="text-center">
                  <v-text-field
                    :disabled="
                      isSscIds.includes(
                        customerContract.id_delivery_contract_inter_provincial
                      )
                    "
                    v-model.number="
                      customerContract.fixed_weight_inter_provincial
                    "
                    class="c-input-small c-input-mini-append-icon"
                    :placeholder="$t('labels.gram')"
                    type="number"
                    append-icon="g"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td class="text-center">Khối lượng tối thiểu</td>
                <td class="text-center error--text font-italic">
                  {{ formatNumber(minWeightProvincial) }} gram
                </td>
                <td class="text-center error--text font-italic">
                  {{ formatNumber(minWeightInterProvincial) }} gram
                </td>
              </tr>
            </template>
          </template>

          <template
            v-if="
              isSscIds.includes(
                customerContract.id_delivery_contract_provincial
              ) ||
              isSscIds.includes(
                customerContract.id_delivery_contract_inter_provincial
              )
            "
          >
            <tr>
              <td class="text-center">Bảng Cước</td>
              <td class="text-center">
                <v-autocomplete
                  class="c-input-xs"
                  v-model="customerContract.cost_by_provincial"
                  :disabled="
                    customerContract.id_delivery_contract_provincial ===
                    tvcContract
                  "
                  :items="costByOptions"
                  :label="$t('labels.delivery_company')"
                  :placeholder="$t('labels.delivery_company')"
                  dense
                  outlined
                  hide-details
                ></v-autocomplete>
              </td>
              <td class="text-center">
                <v-autocomplete
                  class="c-input-xs"
                  v-model="customerContract.cost_by_inter_provincial"
                  :disabled="
                    customerContract.id_delivery_contract_inter_provincial ===
                    tvcContract
                  "
                  :items="costByOptions"
                  :label="$t('labels.delivery_company')"
                  :placeholder="$t('labels.delivery_company')"
                  dense
                  outlined
                  hide-details
                ></v-autocomplete>
              </td>
            </tr>
            <tr>
              <td class="text-center">Tăng cước theo</td>
              <td class="text-center">
                <v-autocomplete
                  class="c-input-xs"
                  v-model="customerContract.add_cost_provincial_by"
                  :disabled="
                    customerContract.id_delivery_contract_provincial ===
                    tvcContract
                  "
                  :items="addCostByOptions"
                  :label="$t('labels.add_cost_by')"
                  :placeholder="$t('labels.add_cost_by')"
                  dense
                  outlined
                  hide-details
                ></v-autocomplete>
              </td>
              <td class="text-center">
                <v-autocomplete
                  class="c-input-xs"
                  v-model="customerContract.add_cost_inter_provincial_by"
                  :disabled="
                    customerContract.id_delivery_contract_inter_provincial ===
                    tvcContract
                  "
                  :items="addCostByOptions"
                  :label="$t('labels.add_cost_by')"
                  :placeholder="$t('labels.add_cost_by')"
                  dense
                  outlined
                  hide-details
                ></v-autocomplete>
              </td>
            </tr>
            <tr>
              <td class="text-center">Tăng</td>
              <td class="text-center error--text font-italic">
                <vuetify-money
                  v-if="customerContract.add_cost_provincial_by === 1"
                  :disabled="
                    customerContract.id_delivery_contract_provincial ===
                    tvcContract
                  "
                  v-model.number="customerContract.add_cost_provincial"
                  :options="moneyOptions"
                  type="number"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></vuetify-money>
                <v-text-field
                  v-if="customerContract.add_cost_provincial_by === 2"
                  :disabled="
                    customerContract.id_delivery_contract_provincial ===
                    tvcContract
                  "
                  v-model.number="customerContract.add_cost_percent_provincial"
                  type="number"
                  class="c-input-small c-input-mini-append-icon"
                  append-icon="%"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td class="text-center error--text font-italic">
                <vuetify-money
                  v-if="customerContract.add_cost_inter_provincial_by === 1"
                  :disabled="
                    customerContract.id_delivery_contract_inter_provincial ===
                    tvcContract
                  "
                  v-model.number="customerContract.add_cost_inter_provincial"
                  :options="moneyOptions"
                  type="number"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></vuetify-money>
                <v-text-field
                  v-if="customerContract.add_cost_inter_provincial_by === 2"
                  :disabled="
                    customerContract.id_delivery_contract_inter_provincial ===
                    tvcContract
                  "
                  v-model.number="
                    customerContract.add_cost_percent_inter_provincial
                  "
                  type="number"
                  class="c-input-small c-input-mini-append-icon"
                  append-icon="%"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
            </tr>
          </template>

          <tr>
            <td class="text-right" colspan="3">
              <v-btn color="success" @click="save">{{
                $t("labels.save")
              }}</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  MONEY_OPTIONS,
  SSC_DELIVERY_CONTRACT,
  TVC_DELIVERY_CONTRACT,
  BY_WEIGHT_DELIVERY_ID,
} from "@/libs/const";

export default {
  name: "CustomerContract",
  props: {
    idCustomer: {
      type: [String, Number],
      default: () => null,
    },
    idWarehouse: {
      type: [String, Number],
      default: () => null,
    },
    idPos: {
      type: [String, Number],
      default: () => null,
    },
  },
  data: () => ({
    deliveryContracts: [],
    sscContracts: [],
    customerContract: {},
    weightConfig: {},
    minWeightProvincial: 0,
    minWeightInterProvincial: 0,
    moneyOptions: { ...MONEY_OPTIONS },
    isLoading: false,
    tvcContract: TVC_DELIVERY_CONTRACT,
    sscContract: SSC_DELIVERY_CONTRACT,
  }),
  computed: {
    addCostByOptions() {
      return [
        {
          text: "Cố định",
          value: 1,
        },
        {
          text: "Theo %",
          value: 2,
        },
      ];
    },
    defaultContracts() {
      const options = [
        {
          id: SSC_DELIVERY_CONTRACT,
          delivery_company_short_name: "SSC",
          status: 1,
          api_cost: 0,
          id_owner: 0,
          is_ssc: 1,
        },
        {
          id: TVC_DELIVERY_CONTRACT,
          delivery_company_short_name: this.$t("labels.tvc"),
          status: 1,
          api_cost: 0,
          id_owner: 0,
          is_ssc: 1,
        },
      ];
      if (
        this.deliveryContracts.length > 1 &&
        this.weightConfig &&
        this.weightConfig.count_id > 1
      ) {
        options.push({
          id: BY_WEIGHT_DELIVERY_ID,
          delivery_company_short_name: this.$t("labels.delivery_by_weight"),
          status: 1,
          api_cost: 0,
          id_owner: 0,
          is_ssc: 1,
        });
      }
      return options;
    },
    deliveryOptions() {
      return [
        ...this.defaultContracts,
        ...this.sscContracts,
        ...this.deliveryContracts,
      ].map((d) => ({
        text: d.delivery_company_short_name,
        value: d.id,
      }));
    },
    sscDeliveryOptions() {
      return [...this.defaultContracts, ...this.sscContracts].map((d) => ({
        text: d.delivery_company_short_name,
        value: d.id,
      }));
    },
    costByOptions() {
      const sscCost = [...this.sscContracts].map((d) => ({
        text: d.delivery_company_short_name,
        value: d.id_delivery_company,
      }));
      sscCost.unshift({
        text: "SSC",
        value: SSC_DELIVERY_CONTRACT,
      });
      return sscCost;
    },
    defaultContractIds() {
      return [SSC_DELIVERY_CONTRACT, TVC_DELIVERY_CONTRACT];
    },
    isSscIds() {
      return [...this.defaultContracts, ...this.sscContracts].map((s) => s.id);
    },
  },
  watch: {
    "customerContract.id_delivery_contract_provincial"() {
      this.getMinWeight();
    },
    "customerContract.id_delivery_contract_inter_provincial"() {
      this.getMinWeight();
    },
    "customerContract.add_cost_provincial_by"() {
      if (!this.customerContract.add_cost_provincial_by) {
        this.customerContract.add_cost_provincial = null;
        this.customerContract.add_cost_percent_provincial = null;
      } else if (this.customerContract.add_cost_provincial_by === 1) {
        this.customerContract.add_cost_percent_provincial = null;
      } else if (this.customerContract.add_cost_provincial_by === 2) {
        this.customerContract.add_cost_provincial = null;
      }
    },
    "customerContract.add_cost_inter_provincial_by"() {
      if (!this.customerContract.add_cost_inter_provincial_by) {
        this.customerContract.add_cost_inter_provincial = null;
        this.customerContract.add_cost_percent_inter_provincial = null;
      } else if (this.customerContract.add_cost_inter_provincial_by === 1) {
        this.customerContract.add_cost_percent_inter_provincial = null;
      } else if (this.customerContract.add_cost_inter_provincial_by === 2) {
        this.customerContract.add_cost_inter_provincial = null;
      }
    },
  },
  mounted() {
    this.getCustomerContract();
    this.getContracts();
  },
  methods: {
    async getCustomerContract() {
      const { data } = await httpClient.post(
        "/delivery/v1/get-customer-contract",
        {
          id_customer: this.idCustomer,
          id_warehouse: this.idWarehouse,
          id_pos: this.idPos,
        }
      );
      const otherData = {
        add_cost_provincial_by: data.add_cost_provincial
          ? 1
          : data.add_cost_percent_provincial
          ? 2
          : null,
        add_cost_inter_provincial_by: data.add_cost_inter_provincial
          ? 1
          : data.add_cost_percent_inter_provincial
          ? 2
          : null,
      };
      this.customerContract = { ...data, ...otherData };
    },
    getMinWeight() {
      if (
        this.isSscIds.includes(
          this.customerContract.id_delivery_contract_provincial
        )
      ) {
        this.minWeightProvincial = 0;
        this.customerContract = {
          ...this.customerContract,
          decrease_weight_provincial: null,
          fixed_weight_provincial: null,
        };
      } else {
        httpClient
          .post("/delivery/v1/get-delivery-contract-by-ssc", {
            id_delivery_contract:
              this.customerContract.id_delivery_contract_provincial,
            id_warehouse: this.idWarehouse,
            id_pos: this.idPos,
          })
          .then(({ data }) => {
            this.minWeightProvincial =
              (data && data.min_weight_after_decrease_provincial) || 0;
          });
      }

      if (
        this.isSscIds.includes(
          this.customerContract.id_delivery_contract_inter_provincial
        )
      ) {
        this.minWeightInterProvincial = 0;
        this.customerContract = {
          ...this.customerContract,
          decrease_weight_inter_provincial: null,
          fixed_weight_inter_provincial: null,
        };
      } else {
        httpClient
          .post("/delivery/v1/get-delivery-contract-by-ssc", {
            id_delivery_contract:
              this.customerContract.id_delivery_contract_inter_provincial,
            id_warehouse: this.idWarehouse,
            id_pos: this.idPos,
          })
          .then(({ data }) => {
            this.minWeightInterProvincial =
              (data && data.min_weight_after_decrease_inter_provincial) || 0;
          });
      }
    },
    getContracts() {
      httpClient
        .post("/delivery/v1/get-delivery-contract-by-customer", {
          id_customer: this.idCustomer,
          id_warehouse: this.idWarehouse,
          id_pos: this.idPos,
          status: 1,
        })
        .then(({ data }) => {
          this.deliveryContracts = [...data.rows];
        });
      httpClient
        .post("/delivery/v1/get-delivery-weight-config-group", {
          id_customer: this.idCustomer,
          id_warehouse: this.idWarehouse,
          id_pos: this.idPos,
        })
        .then(({ data }) => {
          this.weightConfig = { ...data };
        });
      httpClient
        .post("/delivery/v1/get-delivery-contract-by-customer", {
          id_warehouse: this.idWarehouse,
          id_pos: this.idPos,
          id_owner: 0,
          status: 1,
        })
        .then(({ data }) => {
          this.sscContracts = [...data.rows].map((d) => ({
            ...d,
            delivery_company_short_name: `SSC x ${d.delivery_company_short_name}`,
            is_ssc: 1,
          }));
        });
    },
    async save() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/delivery/v1/update-customer-contract", {
          ...this.customerContract,
          id_warehouse: this.idWarehouse,
          id_pos: this.idPos,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
